<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["月租车", "临时车"],
        seriesData: [
          { value: 30, name: "月租车" },
          { value: 100, name: "临时车" }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  created() {
	/*this.fetchList();*/
  },   
  mounted () {
	/*this.changeTiming();*/
  },
  methods: {
    changeTiming() {
      setInterval(() => {
		this.fetchList();
      }, 15000);
    },	
	async fetchList() {
		const listData = await this.$http.post("api/datasystem/parkfee", {
			propertyid: 1
		});
		if(listData['status'] === 200){
			this.cdata.seriesData = listData['data'];
			this.cdata = { ...this.cdata };
		}
	}	  
  }
}
</script>

<style lang="scss" scoped>
</style>