<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="ml-3 colorBlue fw-b fs-xl">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2 pl-3"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1 pl-3">今日车场车流量排名</span>
        <dv-scroll-ranking-board class="dv-scr-rank-board mt-1" :config="ranking" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <CenterChart />
        </div>
        <div class="item bg-color-black">
          <CenterChart1 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from '@/components/echart/center/centerChartError'
import CenterChart1 from '@/components/echart/center/centerChartType'
export default {
  data() {
    return {
      titleItem: [
        {
          title: '今日实时总收入(元)',
          number: {
            number: [0],
            toFixed: 2,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日收入笔数(笔)',
          number: {
            number: [0],
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日车流总量(辆)',
          number: {
            number: [0],
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日入车(辆)',
          number: {
            number: [0],
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日出车(辆)',
          number: {
            number: [0],
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '在场车数(辆)',
          number: {
            number: [0],
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        }
      ],
      ranking: {
        data: [
        ],
		waitTime: 5000,
		sort: true,
        carousel: 'single',
        unit: '辆'
      },
		erroring: {
			data: [
				{
					name: '周口',
					value: 55
				},
				{
					name: '南阳',
					value: 120
				},				
			],
			radius: '40%',
			activeRadius: '45%',	
			digitalFlopStyle: {
				fontSize: 20
			},
			digitalFlopUnit: '次',			
		},
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: 'centerRate1',
          tips: 60,
          colorData: {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: 40,
          colorData: {
            textStyle: '#67e0e3',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
      ]
    }
  },
  components: {
    CenterChart,CenterChart1
  },
  created() {
	this.fetchList();
	this.fetchList1();
  },  
  mounted() {
	this.changeTiming(); //获取数据
	this.changeTiming1(); //获取数据
  },  
  methods: {
    changeTiming() {
      setInterval(() => {
		this.fetchList1();
      }, 10000);
    },
    changeTiming1() {
      setInterval(() => {
        this.fetchList();
      }, 20000);
    },	
	async fetchList() {
		const listData = await this.$http.post("api/datasystem/parkwater", {
			propertyid: 1
		});
		if(listData['status'] === 200){
			this.ranking.data = listData['data'];
			this.ranking = { ...this.ranking };
		}
	},
	async fetchList1() {
		const listData1 = await this.$http.post("api/datasystem/parkdata", {
			propertyid: 1
		});
		if(listData1['status'] === 200){
			this.titleItem.forEach((item, index) => {
				item.number.number[0] = listData1['data'][index];
				item.number = { ...item.number };
			});
		}
	}	
  } 	  
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 100%;
        height: 140px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
