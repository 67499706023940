<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">今日车场总收入量排名</span>
          <dv-decoration-1 class="dv-dec-1" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <dv-scroll-ranking-board class="dv-scr-rank-board mt-1" :config="ranking" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ranking: {
        data: [
        ],
		waitTime: 5000,
		sort: true,
        carousel: 'single',		
        rowNum: 7,
        unit: '元'
      }
    }
  },
  components: {},
  created() {
	this.fetchList();
  },   
  mounted() {
	this.changeTiming();
  },
  methods: {
    changeTiming() {
      setInterval(() => {
        this.fetchList();
      }, 10000);
    },	  
	async fetchList() {
		const listData = await this.$http.post("api/datasystem/parkrank", {
			propertyid: 1
		});
		if(listData['status'] === 200){
			this.ranking.data = listData['data'];
			this.ranking = { ...this.ranking };
		}
	}
  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  $box-width: 300px;
  $box-height: 410px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
      .dv-scr-rank-board {
        height: 350px;
      }	
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
    .dv-scr-board {
      width: 300px;
      height: 340px;
    }  
}
</style>