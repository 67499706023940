<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">车辆实时进出场数据</span>
			<dv-decoration-1 class="dv-dec-1" />
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['停车场', '车牌号', '车辆类型', '状态', '通道', '收费金额'],
        data: [
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        columnWidth: [140,110,90,50,90,120],
        align: ['center','center','center','center','center','center']
      }
    }
  },
  components: {},
  created() {
	this.fetchList();
  },   
  mounted() {
	this.changeTiming();
  },
  methods: {
    changeTiming() {
      setInterval(() => {
        this.fetchList();
      }, 10000);
    },	  
	async fetchList() {
		const listData = await this.$http.post("api/datasystem/parkcaring", {
			propertyid: 1
		});
		if(listData['status'] === 200){
			this.config = {
				header: ['停车场', '车牌号', '车辆类型', '状态', '通道', '收费金额'],
				data: listData['data'],
				rowNum: 7, //表格行数
				headerHeight: 35,
				headerBGC: '#0f1325', //表头
				oddRowBGC: '#0f1325', //奇数行
				evenRowBGC: '#171c33', //偶数行
				columnWidth: [140,110,90,50,90,120],
				align: ['center','center','center','center','center','center']				
			};
		}
	}
  }
}
</script>

<style lang="scss" scoped>
$box-height: 410px;
$box-width: 620px;
#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }  
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 620px;
      height: 340px;
    }
  }
}
</style>
