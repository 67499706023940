<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["今日电子支付", "今日现金支付"],
        seriesData: [
          { value: 0, name: "今日电子支付" },
          { value: 0, name: "今日现金支付" }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  created() {
	this.fetchList();
  },   
  mounted () {
	this.changeTiming(); //获取数据
  },
  methods: {
    changeTiming() {
      setInterval(() => {
		this.fetchList();
      }, 15000);
    },	
	async fetchList() {
		const listData = await this.$http.post("api/datasystem/parkfee", {
			propertyid: 1
		});
		if(listData['status'] === 200){
			this.cdata.seriesData = listData['data'];
			this.cdata = { ...this.cdata };
		}
	}	  
  }
}
</script>

<style lang="scss" scoped>
</style>