<template>
  <div>
    <Echart
      :options="options"
      id="centreErrorChart"
      height="140px"
      width="260px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
			title: {
				text: '异常比率',
				left: 'center',
				top: '60px',
				textStyle:{
					//文字颜色
					color:'#ccc',
					//字体风格,'normal','italic','oblique'
					fontStyle:'normal',
					//字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
					fontWeight:'bold',
					//字体系列
					fontFamily:'sans-serif',
					//字体大小
					fontSize:14
				}				
			},			
          color: [
            "#32c5e9",
            "#ff9f7f",			  
			"#ffdb5c",
            "#9fe6b8",			  
            "#37a2da",
            "#fb7293",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          series: [
            {
              name: "异常比率统计",
              type: "pie",
				top: '53px',
              radius: [45, 50],
              /*roseType: "area",*/
              center: ["50%", "20%"],
              data: newData.seriesData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>