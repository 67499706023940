<template>
	<div>
		<Echart :options="options" id="centreLeft1Chart" height="240px" width="100%"></Echart>
	</div>
</template>

<script>
	import Echart from '@/common/echart'
	export default {
		data() {
			return {
				options: {},
			};
		},
		components: {
			Echart,
		},
		props: {
			cdata: {
				type: Object,
				default: () => ({})
			},
		},
		watch: {
			cdata: {
				handler(newData) {
					this.options = {
						title: {
							text: '7日车流量趋势分析',
							textStyle:{
								//文字颜色
								color:'#ccc',
								//字体风格,'normal','italic','oblique'
								fontStyle:'normal',
								//字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
								fontWeight:'bold',
								//字体系列
								fontFamily:'sans-serif',
								//字体大小
								fontSize:14
							}							
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'cross',
								label: {
									backgroundColor: '#6a7985'
								}
							}
						},
						legend: {
							data: ['总流量', '临时车', '月租车']
						},
						/*
						toolbox: {
							feature: {
								saveAsImage: {}
							}
						},
						*/
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: [{
							type: 'category',
							boundaryGap: false,
							data: newData.weekCategory
						}],
						yAxis: [{
							type: 'value'
						}],
						series: [
							{
								name: '月租车',
								type: 'line',
								stack: 'Total',
								areaStyle: {},
								smooth: true,
								emphasis: {
									focus: 'series'
								},
								data: [150, 232, 201, 154, 190, 330, 410]
							},
							{
								name: '临时车',
								type: 'line',
								stack: 'Total',
								areaStyle: {},
								smooth: true,
								emphasis: {
									focus: 'series'
								},
								data: [320, 332, 301, 334, 390, 330, 320]
							},
							{
								name: '总流量',
								type: 'line',
								stack: 'Total',
								label: {
									show: true,
									position: 'top'
								},
								smooth: true,
								areaStyle: {},
								emphasis: {
									focus: 'series'
								},
								data: [820, 932, 901, 934, 1290, 1330, 1320]
							}
						]
					}
				},
				immediate: true,
				deep: true
			}
		}
	};
</script>
